import manifest from '@/assets/logos/manifest.json'
import logos from '@/assets/logos.svg'

export type Props = {
  className?: string
  name: Name
  ariaLabel?: string
}

type Name =
  | 'attentive-darkmode'
  | 'attentive'
  | 'barstool-darkmode'
  | 'barstool'
  | 'block'
  | 'block-darkmode'
  | 'community-darkmode'
  | 'community'
  | 'etsy'
  | 'gusto'
  | 'intercom'
  | 'intercom-darkmode'
  | 'kick'
  | 'myfitnesspal-darkmode'
  | 'myfitnesspal'
  | 'slack-darkmode'
  | 'slack'

export default function Logo({ name, ariaLabel, ...props }: Props) {
  const viewBox = manifest[name]

  return (
    <svg {...props} viewBox={viewBox} aria-label={ariaLabel}>
      <use href={`${logos}#${name}`} />
    </svg>
  )
}
